import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';

export default class extends Controller {
  static targets = ['selectAllButton', 'unselectAllButton'];

  connect() {
    // Have to check if Choices it's already instantiated as the way this library internally removes and adds a select element multiple times
    // which makes Stimulus JS call connect() method multiple times and trying to initialize Choices which was showing warnings in the console
    const select =
      this.element.nodeName === 'SELECT' ? this.element : this.element.querySelector('select');
    if (!select.closest('.choices')) {
      this.choices = new Choices(select, {
        removeItemButton: true,
        noChoicesText: 'No options to choose from',
        placeholderValue: 'Please select…',
        allowHTML: false,
        shouldSort: false,
        classNames: {},
      });

      const { minHeight } = this.element.style;
      const { maxHeight } = this.choices.passedElement.element.style;
      if (minHeight) {
        this.choices.containerInner.element.style.minHeight = minHeight;
      }

      if (maxHeight) {
        this.choices.dropdown.element.style.maxHeight = maxHeight;
      }

      this.element.addEventListener('prefill:prefillSelect', (e) => {
        this.choices.setChoiceByValue(e.detail.values);
      });

      // Toggle placeholder on init & on change based on whether there is at least 1 item selected
      setTimeout(() => this.togglePlaceholder(), 200);
      select.addEventListener('change', () => this.togglePlaceholder());
      select.addEventListener('addItem', () => this.togglePlaceholder());
      select.addEventListener('removeItem', () => this.togglePlaceholder());
    }
  }

  selectAll() {
    this.choices.config.choices.forEach(({ value }) => {
      this.choices.setChoiceByValue(value);
    });
    this.selectAllButtonTarget.classList.add('d-none');
    this.unselectAllButtonTarget.classList.remove('d-none');
  }

  unselectAll() {
    this.choices.removeActiveItems();
    this.selectAllButtonTarget.classList.remove('d-none');
    this.unselectAllButtonTarget.classList.add('d-none');
  }

  togglePlaceholder() {
    const { placeholderValue } = this.choices.config;
    const placeholderEl = this.choices.containerInner.element.querySelector(
      `[placeholder="${placeholderValue}"]`
    );

    if (placeholderEl) {
      placeholderEl.classList.toggle('d-none', this.choices.getValue(true).length > 0);
    }
  }
}
